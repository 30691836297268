import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import AddToWishListButton from '../Products/AddToWishListButton';
import Labels from '../Labels/Labels';
import DropshipTime from '../DeliveryTimeApi/dropshipTime';
import Price from '../Price';
import decodeEntity from '../../util/decodeEntity';
import Variants from './variants';

const ProductCardFindologic = (props) => {
    const { product, productUrlSuffix } = props;
    const currency = 'EUR';
    const saleStores = product?.properties?.sale_stores;
    const productState = product?.properties?.product_state?.trim();
    const oldPrice = Number(product?.properties?.old_price);
    const isWerbung = Number(product?.properties?.werbung) == 1;
    const isRelatedColor = product?.attributes?.related_color;
    const isRelatedSize = product?.attributes?.related_size;
    const isFastDelivery =
        Number(product?.properties?.fast_delivery) == 1 ? ' Auf Lager' : null;
    const price = product?.price.toLocaleString('de-DE', {
        style: 'currency',
        currency
    });
    const discount =
        product.price == undefined
            ? ((product.properties.old_price - product.price) /
                  product.properties.old_price) *
              100
            : ((product.properties.old_price - product.price) /
                  product.properties.old_price) *
              100;
    const url = new URL(product.url)?.pathname; //TODO REMOVE THIS REPLACEMENT
    const thumbnailUrl = product.imageUrl
        ? new URL(product.imageUrl)?.pathname
        : '/pub/media/catalog/product/placeholder/default/placeholder.jpg';
    const vendor =
        product?.attributes?.vendor?.length > 0
            ? product?.attributes?.vendor[0]
            : '';

    const priceRange =
        price && oldPrice
            ? {
                  minimum_price: {
                      final_price: { value: price, currency },
                      discount: { amount_off: discount },
                      regular_price: { value: oldPrice, currency }
                  }
              }
            : price
            ? {
                  minimum_price: {
                      final_price: { value: price, currency },
                      discount: { amount_off: discount },
                      regular_price: null
                  }
              }
            : null;

    console.log("'price_tag'", product);
    return (
        <div
            className="relative cursor-pointer border border-solid border-skin-primary"
            key={product.id}
        >
            <Link passHref href={url}>
                <a
                    className="hover:text-skin-base"
                    onClick={() =>
                        localStorage.setItem(
                            'categoryScrolledIndex',
                            document.documentElement.scrollTop
                        )
                    }
                >
                    <div
                        key={product.id}
                        className="group pb-4 flex flex-col justify-between h-full"
                    >
                        <div>
                            <div className="w-full bg-white aspect-w-1 aspect-h-1 overflow-hidden group-hover:opacity-75">
                                <Image
                                    blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mO8Uw8AAj0BXe1cD0IAAAAASUVORK5CYII="
                                    layout="fill"
                                    src={thumbnailUrl}
                                    alt={product.name}
                                    sizes="(max-width: 700px) 100px, (max-width: 1024px) 250px, (min-width: 1025px) 350px"
                                    className="!max-w-[90%] !max-h-[90%] !min-w-[90%] !min-h-[90%] object-center object-contain"
                                />
                            </div>
                            <div className="relative mt-6 px-4">
                                <span className="absolute -top-4 block text-xs text-left text-skin-muted">
                                    {vendor}
                                </span>
                                <span className="text-sm md:text-md lg:text-base font-semibold line-clamp-2 group-hover:text-skin-primary-hover text-left lg:leading-7">
                                    {decodeEntity(product.name)}
                                </span>
                                {saleStores ? (
                                    <span className="mt-2 text-xs block lg:text-base min-h-[20px] lg:min-h-[24px]">
                                        <span className="text-sm flex flex-start items-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 12.069 14.993"
                                                className="h-4 w-4 mr-1 cursor-pointer hover:text-gray-500"
                                            >
                                                <path
                                                    data-name="Pfad 135"
                                                    d="M10.305 1.842a5.875 5.875 0 00-8.543 0A6.361 6.361 0 000 6.273a6.362 6.362 0 001.76 4.433l4 4.172a.375.375 0 00.53.011l.011-.011 4-4.172a6.362 6.362 0 001.768-4.431 6.362 6.362 0 00-1.762-4.431zm-.541 8.345l-3.726 3.89-3.733-3.89a5.706 5.706 0 010-7.823 5.125 5.125 0 017.464 0 5.706 5.706 0 01-.005 7.823zm0 0"
                                                ></path>
                                                <path
                                                    data-name="Pfad 136"
                                                    d="M6.034 2.293a3.366 3.366 0 103.379 3.366 3.366 3.366 0 00-3.379-3.366zm0 5.985A2.618 2.618 0 118.662 5.66a2.618 2.618 0 01-2.628 2.618zm0 0"
                                                ></path>
                                            </svg>
                                            {saleStores}
                                        </span>
                                    </span>
                                ) : null}
                                <span className="mt-2 text-xs font-semibold text-skin-green block lg:text-base min-h-[20px] lg:min-h-[24px]">
                                    <span className="text-sm">
                                        {isFastDelivery ? isFastDelivery : null}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="mt-2 ">
                            <div className="flex justify-between flex-col items-start w-full px-4">
                                <span
                                    className={
                                        'absolute top-4 md:top-5 left-0 flex flex-row justify-start items-start sm:items-center'
                                    }
                                >
                                    {discount > 0 && (
                                        <span className="mb-1 sm:mb-0">
                                            <Labels
                                                label="discount"
                                                percent_off={Math.ceil(
                                                    discount
                                                )}
                                                labelClass={''}
                                            />
                                        </span>
                                    )}

                                    {isWerbung && <Labels label="werbung" />}
                                    {!isWerbung && productState == 'Y' && (
                                        <Labels label="topseller" />
                                    )}

                                    {!isWerbung &&
                                        productState != 'Y' &&
                                        product?.attributes?.price_tag?.includes(
                                            '40'
                                        ) && <Labels label="optiangebote" />}

                                    {!isWerbung &&
                                    !product?.attributes?.price_tag?.includes(
                                        '40'
                                    ) &&
                                    (productState == '9' ||
                                        productState == 'L' ||
                                        productState == '8' ||
                                        productState == 'S') ? (
                                        <Labels label="sale" />
                                    ) : null}

                                    {!isWerbung &&
                                        !product?.attributes?.price_tag?.includes(
                                            '40'
                                        ) &&
                                        productState == 'I' && (
                                            <Labels label="online" />
                                        )}
                                </span>

                                {/*TODO to make it not static*/}
                                {/* <Price {...product.price_range} /> */}
                            </div>
                            <div className="flex justify-between flex-row items-end w-full mt-auto px-4">
                                <div>
                                    <Variants
                                        isRelatedColor={isRelatedColor}
                                        isRelatedSize={isRelatedSize}
                                    />
                                </div>
                                {product && priceRange ? (
                                    <Price
                                        priceRange={priceRange}
                                        discountClass="text-xs text-skin-muted flex md:text-base font-normal line-through"
                                        priceClass="flex flex-col items-end text-base text-skin-muted text-skin-secondary font-bold md:text-xl"
                                        priceTag={
                                            product?.attributes?.price_tag?.includes(
                                                'U1'
                                            )
                                                ? 'UVP*'
                                                : null
                                        }
                                    />
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <AddToWishListButton
                        product={{
                            sku: String(product.properties.sku)
                        }}
                    />
                </a>
            </Link>
        </div>
    );
};

export default ProductCardFindologic;
