import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import SortOptions from './sortOptions';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { SortDescendingIcon } from '@heroicons/react/solid';
import { useRouter } from 'next/router';
import { returnSortType } from '../../util/urlManipulatingFunctions';

type sortFieldsOptionType = {
    label: string;
    value: string;
}[];

type sortFieldsType = {
    defaultValue: string;
    options: sortFieldsOptionType;
};
interface SortProps {
    sortFields: sortFieldsType;
    className?: string;
    setSortLoading: Function;
}

const Sort = ({ sortFields, className, setSortLoading }: SortProps) => {
    const router = useRouter();
    const sortObj = returnSortType(router);
    const selectedSort =
        sortObj && Array.isArray(Object.keys(sortObj))
            ? Object.keys(sortObj)[0]
            : null;

    const selectedSortValue = selectedSort && sortObj[selectedSort];

    const defaultSorting = sortFields?.defaultValue;
    const filteredOptions = sortFields.options.filter(
        (f) => f.value == selectedSort
    );
    const sortValue: { label: string; value: string }[] | '' | 'Topseller' =
        sortFields &&
        Array.isArray(sortFields.options) &&
        filteredOptions.length > 0
            ? filteredOptions
            : defaultSorting == 'salesfrequency DESC'
            ? 'Topseller'
            : '';
    return (
        <>
            <Menu
                as="div"
                className={
                    'hidden relative text-left lg:inline-block' +
                    ' ' +
                    className
                }
            >
                {({ open }) => (
                    <>
                        <Menu.Button
                            className={`group inline-flex justify-center items-center text-base font-medium whitespace-nowrap ${
                                filteredOptions.length > 0
                                    ? 'text-skin-bolder-hover'
                                    : ''
                            }`}
                        >
                            Sortieren:
                            <strong className="mx-4">
                                {sortValue && Array.isArray(sortValue)
                                    ? `${
                                          sortValue[0]?.label == 'Topseller'
                                              ? 'Verfügbarkeit'
                                              : sortValue[0]?.label
                                      } ${
                                          sortValue[0]?.label != 'Topseller'
                                              ? selectedSortValue == 'ASC'
                                                  ? 'Aufsteigend'
                                                  : 'Absteigend'
                                              : ''
                                      }`
                                    : sortValue == 'Topseller'
                                    ? 'Verfügbarkeit'
                                    : sortValue}
                            </strong>
                            {open ? (
                                <ChevronDownIcon className="w-6 h-6" />
                            ) : (
                                <ChevronRightIcon className="w-6 h-6" />
                            )}
                        </Menu.Button>

                        <Transition
                            as={'div'}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                            className={'z-20 relative'}
                        >
                            <SortOptions
                                sortFields={sortFields}
                                setSortLoading={setSortLoading}
                            />
                        </Transition>
                    </>
                )}
            </Menu>
            <Menu
                as="div"
                className={
                    'relative inline-block text-left lg:hidden' +
                    ' ' +
                    className
                }
            >
                <Menu.Button className="w-full flex flex-row-reverse justify-center items-center p-2 border border-solid border-skin-primary border-b-0 border-l-0 border-r-0">
                    <div
                        className={`flex flex-row-reverse justify-end items-center ${
                            filteredOptions.length > 0
                                ? 'text-skin-bolder-hover'
                                : ''
                        }`}
                    >
                        <span className="text-sm font-semibold">Sortieren</span>
                        <SortDescendingIcon
                            className="w-6 h-6 mr-3"
                            aria-hidden="true"
                        />
                    </div>
                </Menu.Button>

                <Transition
                    as={'div'}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                    className={'z-20 relative'}
                >
                    <SortOptions
                        sortFields={sortFields}
                        setSortLoading={setSortLoading}
                    />
                </Transition>
            </Menu>
        </>
    );
};

export default Sort;
