import qs from 'qs';
import axios from 'axios';
import decodeQueryParam from '../../util/decodeQueryParam';
import listFindlogicFilters from '../../util/listWithpossibleFindlogicFilters';

interface UseFindlogicProps {
    setLoading: Function;
    setInitLoading: Function;
    setSortLoading: Function;
}

export const useFindlogic = (props: UseFindlogicProps) => {
    const { setLoading, setInitLoading, setSortLoading } = props;
    const pageSize = 34;

    const filteredFilters = (filters: any) =>
        Object.fromEntries(
            Object.entries(filters).filter(([key]) =>
                listFindlogicFilters.includes(key)
            )
        );

    const getResultsSearch = (
        query: string,
        setData: Function,
        attribsFromUrl: string,
        sortString: string,
        currentPage: number
    ) => {
        const handledFilters = filteredFilters(attribsFromUrl);
        axios
            .get('/findlogic-search', {
                //requestedUrl
                params: {
                    query: query,
                    shopkey: '8E36BC284E2AFC9493004E193DA425DA',
                    outputAdapter: 'JSON_1.0',
                    // userip: '165.22.81.243',
                    properties: [
                        'old_price',
                        'sku',
                        'sale',
                        'werbung',
                        'free_shipping_local',
                        'attribute_set_id',
                        'filtercolor',
                        'filtercolor_fkh',
                        'product_state'
                    ],
                    count: pageSize,
                    first: (currentPage - 1) * pageSize,
                    attrib: handledFilters,
                    outputAttrib: [
                        'vendor',
                        'kitchen_kind',
                        'furniture_kind',
                        'lamp_kind',
                        'color',
                        'color_lamp',
                        'stores_opti',
                        'kitchen_color',
                        'brand',
                        'filtercolor',
                        'filtercolor_fkh',
                        'product_state',
                        'price_tag'
                    ],
                    order: decodeQueryParam(sortString)
                },
                paramsSerializer: qs.stringify
            })
            .then((response) => {
                setData(response.data);
            })
            .then(() => {
                setLoading(false);
                setInitLoading(false);
                setSortLoading(false);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const getResultsCategory = (
        setData: Function,
        attribsFromUrl: any,
        sortString: string,
        currentPage: number,
        categoryName: string
    ) => {
        const handledFilters = filteredFilters(attribsFromUrl);
        handledFilters['cat'] = [categoryName];
        setLoading(true);
        axios
            .get('/findlogic-category', {
                params: {
                    shopkey: '8E36BC284E2AFC9493004E193DA425DA',
                    outputAdapter: 'JSON_1.0',
                    userip: '165.22.81.243',
                    properties: [
                        'old_price',
                        'sku',
                        'sale',
                        'werbung',
                        'free_shipping_local',
                        'attribute_set_id',
                        'fast_delivery',
                        'filtercolor',
                        'filtercolor_fkh',
                        'product_state'
                    ],
                    count: pageSize,
                    first: (currentPage - 1) * pageSize,
                    attrib: handledFilters, //HERE WE HAVE TO PASS THE CATEGORY
                    outputAttrib: [
                        'vendor',
                        'kitchen_kind',
                        'furniture_kind',
                        'lamp_kind',
                        'color',
                        'color_lamp',
                        'stores_opti',
                        'kitchen_color',
                        'brand',
                        'filtercolor',
                        'filtercolor_fkh',
                        'product_state',
                        'price_tag'
                    ],
                    order: decodeQueryParam(sortString)
                },
                paramsSerializer: qs.stringify
            })
            .then((response) => {
                setData(response.data);
            })
            .then(() => {
                setLoading(false);
                setInitLoading(false);
                setSortLoading(false);
            })
            .catch((e) => {
                console.log(e);
            });
    };
    const getProductsByCategory = (
        setData: Function,
        categoryName: string,
        currentPage: number
    ) => {
        setLoading(true);
        axios
            .get('/findlogic-category-products', {
                params: {
                    outputAdapter: 'JSON_1.0',
                    userip: '165.22.81.243',
                    properties: [
                        'old_price',
                        'sku',
                        'sale',
                        'werbung',
                        'free_shipping_local',
                        'attribute_set_id',
                        'fast_delivery',
                        'filtercolor',
                        'filtercolor_fkh',
                        'product_state',
                        'hob',
                        'sale_stores'
                    ],
                    count: pageSize,
                    first: (currentPage - 1) * pageSize,
                    attrib: { cat: [categoryName] }, //HERE WE HAVE TO PASS THE CATEGORY
                    outputAttrib: [
                        'vendor',
                        'kitchen_kind',
                        'furniture_kind',
                        'lamp_kind',
                        'color',
                        'color_lamp',
                        'stores_opti',
                        'kitchen_color',
                        'brand',
                        'filtercolor',
                        'filtercolor_fkh',
                        'cooker_hood_energieclass',
                        'dishwasher_energieclass',
                        'freezer_energieclass',
                        'oven_energieclass',
                        'product_state',
                        'price_tag',
                        'opticard_special_price_display'
                    ]
                },
                paramsSerializer: qs.stringify
            })
            .then((response) => {
                setData(response.data);
            })
            .then(() => {
                setLoading(false);
                setInitLoading(false);
                setSortLoading(false);
                sessionStorage.removeItem('parentCategory');
            })
            .catch((e) => {
                console.log(e);
            });
    };
    return {
        getResultsSearch,
        getResultsCategory,
        getProductsByCategory
    };
};
