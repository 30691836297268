import React, { Fragment } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import CategoryFilters from '../CategoryFilters/categoryFilters';
import { connect } from 'react-redux';
import { toggleDrawer } from '../../store/actions/app';
import { sidebarLoadingTemplate } from '../LoadingContent/loadingTemplates';
import CloseIcon from './close.svg';

interface FilterSidebarProps {
    categoryData?: {
        children: {
            id: string;
            url_path: string;
            url_suffix: string;
            name: string;
        }[];
        id: number;
        product_count: number;
    };
    drawer?: string;
    toggleFiltersSidebar: Function;
    filters?: {
        amshopby_filter_data: {
            categories_filter: string;
            visible_in_categories?: string;
            block_position?: number;
            is_tooltips_enabled: boolean;
            tooltip?: string;
        }[];
        attribute_code: string;
        label: string;
        options: {
            value: string;
            label: string;
            count: number;
            image?: string | undefined;
            description?: string | undefined;
        }[];
    }[];
    loading?: boolean;
    childrenMode?: boolean;
    children: any;
}

const FilterSidebar = (props: FilterSidebarProps) => {
    const {
        categoryData,
        drawer,
        toggleFiltersSidebar,
        filters,
        loading,
        childrenMode
    } = props;
    const filterOpened = drawer === 'filter';
    return (
        <Transition.Root show={filterOpened} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 flex z-40"
                onClose={() =>
                    toggleFiltersSidebar ? toggleFiltersSidebar() : null
                }
            >
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                >
                    <div className="ml-auto relative w-full h-full bg-white shadow-xl flex flex-col overflow-y-auto sm:max-w-sm">
                        <div className="flex items-center justify-between">
                            <h2 className="pl-4 text-lg mb-0">Filtern</h2>
                            <button
                                type="button"
                                className="p-4"
                                onClick={() =>
                                    toggleFiltersSidebar &&
                                    toggleFiltersSidebar()
                                }
                            >
                                <span className="sr-only">Close menu</span>
                                <CloseIcon aria-hidden="true" />
                            </button>
                        </div>

                        {loading ? sidebarLoadingTemplate : null}

                        {loading ? (
                            <div className="block w-full h-full absolute top-0 left-0 bg-white opacity-50 z-10" />
                        ) : null}

                        {/* Filters */}

                        {childrenMode ? (
                            props.children
                        ) : categoryData &&
                          categoryData.id &&
                          filters &&
                          loading != undefined ? (
                            <CategoryFilters
                                selectedFilters={filters}
                                productCount={categoryData.product_count}
                                categoryId={categoryData.id}
                                loading={loading}
                                toggleFiltersSidebar={toggleFiltersSidebar}
                            />
                        ) : null}
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition.Root>
    );
};

const mapStateToProps = ({ app: { drawer } }: { app: { drawer: string } }) => {
    return {
        drawer
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        toggleFiltersSidebar: () => dispatch(toggleDrawer('filter'))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterSidebar);
