import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useRouter } from 'next/router';
import { addParamToUrl } from '../../util/urlManipulatingFunctions';
// import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid';

type sortFieldsOptionType = {
    label: string;
    value: string;
}[];

type sortFieldsType = {
    defaultValue: string;
    options: sortFieldsOptionType;
};
interface SortOptionsProps {
    sortFields: sortFieldsType;
    setSortLoading: Function;
}

const SortOptions = ({ sortFields, setSortLoading }: SortOptionsProps) => {
    const router = useRouter();
    const selectedSort: string[] | null =
        typeof router?.query?.sort === 'string'
            ? router?.query?.sort?.split(' ')
            : null;

    return (
        <Transition
            as={'div'}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <Menu.Items className="origin-top-left w-full absolute left-0  shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                    {sortFields.options.map((option) => {
                        return [
                            option.label != 'Topseller' ? (
                                <Menu.Item key={option.label + ' ASC'}>
                                    <button
                                        type={'button'}
                                        className={
                                            selectedSort &&
                                            selectedSort[0] == option.value &&
                                            selectedSort[1] == 'ASC'
                                                ? 'flex font-medium text-gray-900 bg-gray-100 px-4 py-2 text-sm w-full justify-between'
                                                : 'flex text-gray-500 block px-4 py-2 text-sm w-full justify-between text-left'
                                        }
                                        onClick={() => {
                                            addParamToUrl(
                                                router,
                                                'sort',
                                                option.value + ' ASC',
                                                false,
                                                true
                                            );
                                            setSortLoading(true);
                                        }}
                                    >
                                        <span className="whitespace-nowrap">
                                            {option.label} Aufsteigend
                                        </span>
                                        {/* <span className="min-w-5 block">
                                        <ArrowUpIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </span> */}
                                    </button>
                                </Menu.Item>
                            ) : null,
                            <Menu.Item key={option.label + ' DESC'}>
                                <button
                                    type={'button'}
                                    className={
                                        selectedSort &&
                                        selectedSort[0] == option.value &&
                                        selectedSort[1] == 'DESC'
                                            ? 'flex font-medium text-gray-900 bg-gray-100 px-4 py-2 text-sm w-full justify-between'
                                            : 'flex text-gray-500 block px-4 py-2 text-sm w-full justify-between text-left'
                                    }
                                    onClick={() => {
                                        addParamToUrl(
                                            router,
                                            'sort',
                                            option.value + ' DESC',
                                            false,
                                            true
                                        );
                                        setSortLoading(true);
                                    }}
                                >
                                    <span className="whitespace-nowrap">
                                        {option.label == 'Topseller'
                                            ? 'Verfügbarkeit'
                                            : option.label}{' '}
                                        {option.label != 'Topseller'
                                            ? 'Absteigend'
                                            : null}
                                    </span>
                                    {/* <span className="min-w-5 block">
                                        <ArrowDownIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </span> */}
                                </button>
                            </Menu.Item>
                        ];
                    })}
                </div>
            </Menu.Items>
        </Transition>
    );
};

export default SortOptions;
