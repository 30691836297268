import { Disclosure } from '@headlessui/react';
import FilterOptions from '../FilterSidebar/filterOptions';
import FiltersCurrents from '../FilterSidebar/filtersCurrents';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { useRouter } from 'next/router';

type filtersType = {
    amshopby_filter_data: {
        categories_filter: string;
        visible_in_categories?: string;
        block_position?: number;
        is_tooltips_enabled: boolean;
        tooltip?: string;
        display_mode_label?: string;
    }[];
    attribute_code: string;
    label: string;
    options: {
        value: string;
        label: string;
        count: number;
        image?: string | undefined;
        description?: string | undefined;
    }[];
}[];
interface CategoryFilters {
    selectedFilters: filtersType;
    productCount: number;
    categoryId: number;
    loading: boolean;
    classes?: string;
    toggleFiltersSidebar: Function;
}

const CategoryFilters = ({
    selectedFilters,
    productCount,
    categoryId,
    loading,
    classes,
    toggleFiltersSidebar
}: CategoryFilters) => {
    const router = useRouter();
    const routerQuery = router.query;
    const elsToExclude = ['pageUrl', 'sort', 'cat'];
    const handledQuery = Object.fromEntries(
        Object.entries(routerQuery).filter(
            ([key]) => !elsToExclude.includes(key)
        )
    );

    return (
        <div>
            <div className={classes}>
                {selectedFilters && productCount > 0 ? (
                    <FiltersCurrents
                        filters={selectedFilters}
                        loading={loading}
                    />
                ) : null}
                {productCount > 0 &&
                    selectedFilters.map((section: any) => {
                        const selectedCategoriesArr =
                            section.amshopby_filter_data.categories_filter &&
                            section.amshopby_filter_data.categories_filter.split(
                                ','
                            );
                        const hideFilterSection =
                            (section.amshopby_filter_data
                                .visible_in_categories ===
                                'only_in_selected_categories' &&
                                !selectedCategoriesArr?.includes(
                                    String(categoryId)
                                )) ||
                            (section.amshopby_filter_data
                                .visible_in_categories ===
                                'hide_in_selected_categories' &&
                                selectedCategoriesArr?.includes(
                                    String(categoryId)
                                ))
                                ? true
                                : false;
                        return (section?.amshopby_filter_data?.block_position ==
                            0 ||
                            section?.amshopby_filter_data?.block_position ==
                                null) &&
                            !hideFilterSection ? (
                            <div
                                key={section.attribute_code}
                                className="px-6 lg:px-0 py-2"
                            >
                                <Disclosure
                                    as="div"
                                    key={section.attribute_code}
                                    defaultOpen={true}
                                >
                                    {({ open }) => (
                                        <>
                                            <h3 className="-mx-2 -my-3 flow-root border-solid border-t border-skin-primary">
                                                <Disclosure.Button className="px-2 pt-3 pb-5 w-full flex items-center justify-between pr-0.5">
                                                    <span className="font-bold text-base text-left">
                                                        {section.label}
                                                        {section
                                                            ?.amshopby_filter_data
                                                            ?.is_tooltips_enabled ? (
                                                            <span className="text-sm text-skin-primary ml-1">
                                                                {
                                                                    section
                                                                        ?.amshopby_filter_data
                                                                        ?.tooltip
                                                                }
                                                            </span>
                                                        ) : null}
                                                    </span>
                                                    <span className="ml-6 flex items-center">
                                                        {open ? (
                                                            <ChevronUpIcon className="h-7 w-7" />
                                                        ) : (
                                                            <ChevronDownIcon className="h-7 w-7" />
                                                        )}
                                                    </span>
                                                </Disclosure.Button>
                                            </h3>

                                            <Disclosure.Panel className="pt-2 pb-2">
                                                <FilterOptions
                                                    options={section.options}
                                                    attributeCode={
                                                        section.attribute_code
                                                    }
                                                    loading={loading}
                                                    display_mode_label={
                                                        section
                                                            .amshopby_filter_data
                                                            .display_mode_label
                                                    }
                                                />
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            </div>
                        ) : null;
                    })}
                {
                    <div className="bg-white border-t border-skin-primary border-solid py-5 px-4 sticky bottom-0 left-0">
                        <button
                            className="styled-button w-full rounded"
                            onClick={() => {
                                toggleFiltersSidebar();
                            }}
                            disabled={
                                (Object.keys(handledQuery).length === 0 &&
                                    handledQuery.constructor === Object) ||
                                (Array.isArray(handledQuery) &&
                                    handledQuery.length == 0)
                            }
                        >
                            Anwenden
                        </button>
                    </div>
                }
            </div>
        </div>
    );
};

export default CategoryFilters;
