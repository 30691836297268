import { useRouter } from 'next/router';
import { XIcon } from '@heroicons/react/outline';
import { removeParamFromUrl } from '../../util/urlManipulatingFunctions';
import { yesAndNoList } from './filtersTypes';
import listFindlogicFilters from '../../util/listWithpossibleFindlogicFilters';

type handledArrType = {
    filterKey: string;
    value: string | string[];
};

type routerQueryType = {
    pageUrl?: string | string[];
};

const FiltersCurrents = ({
    filters,
    loading,
    scrollRef,
    mobileScrollRef
}: {
    filters: object;
    loading: boolean;
    scrollRef?: React.RefObject<HTMLElement>;
    mobileScrollRef?: React.RefObject<HTMLElement>;
}) => {
    const router = useRouter();
    const routerQuery: routerQueryType = router.query;
    const handledQuery = Object.fromEntries(Object.entries(routerQuery));
    const handledArr:
        | handledArrType[]
        | { filterKey: string; value: string | string[] } = [];
    for (const property in handledQuery) {
        !handledArr.includes({
            filterKey: property,
            value: handledQuery[property]
        }) && listFindlogicFilters.includes(property.replace('slider_', ''))
            ? handledArr.push({
                  filterKey: property,
                  value: handledQuery[property]
              })
            : null;
    }

    const getFilterValue = (
        filterCode: string,
        filterValCode: string | string[]
    ) => {
        const handledFilterCode = filterCode.startsWith('slider_')
            ? String(filterCode).replace('slider_', '')
            : filterCode;
        const existingFilter = Array.isArray(filters)
            ? filters.filter(
                  (f: { name: string }) => f.name == handledFilterCode
              )
            : null;
        const existingVal =
            existingFilter &&
            existingFilter[0] &&
            existingFilter[0].values.filter(
                (f: { value: string }) => f.value == filterValCode
            );

        if (yesAndNoList.includes(handledFilterCode)) {
            return existingVal[0].value;
        }

        return filterCode.startsWith('slider_')
            ? String(filterValCode).split('_')[0] +
                  '-' +
                  String(filterValCode).split('_')[1]
            : existingVal && existingVal.length > 0
            ? existingVal[0].value
            : null;
    };

    const getFilterCodeLabel = (filterCode: string) => {
        const handledFilterCode = filterCode.startsWith('slider_')
            ? String(filterCode).replace('slider_', '')
            : filterCode;
        const existingFilter = Array.isArray(filters)
            ? filters.filter(
                  (f: { name: string }) => f.name == handledFilterCode
              )
            : null;
        return (
            existingFilter && existingFilter[0] && existingFilter[0].displayName
        );
    };

    const currencyLabel = ' €';

    return (
        <div className={'flex flex-wrap px-4 pb-4 lg:px-0'}>
            {handledArr.map(
                (filterItem: {
                    filterKey: string;
                    value: string | string[];
                }) => {
                    if (Array.isArray(filterItem.value)) {
                        return filterItem.value.map((val: string) => {
                            const filterLabel = getFilterValue(
                                filterItem.filterKey,
                                val
                            );
                            const filterCode = getFilterCodeLabel(
                                filterItem.filterKey
                            );
                            return filterLabel ? (
                                <button
                                    type="button"
                                    onClick={() => {
                                        if (
                                            window.innerWidth <= 1024 &&
                                            mobileScrollRef
                                        ) {
                                            mobileScrollRef.current?.scrollIntoView();
                                        } else {
                                            scrollRef?.current?.scrollIntoView();
                                        }

                                        removeParamFromUrl(
                                            router,
                                            filterItem.filterKey,
                                            val
                                        );
                                    }}
                                    className={
                                        'block relative bg-gray-100 rounded px-2 py-1 pr-6 mr-1.5 mb-1.5 hover:bg-gray-300 disabled:text-gray-300 disabled:hover:bg-gray-200'
                                    }
                                    key={val}
                                    disabled={loading}
                                >
                                    <XIcon
                                        className="h-3 w-3 absolute right-1 top-1.5"
                                        aria-hidden="true"
                                    />
                                    <span className="block !text-xs text-left text-gray-400">
                                        {filterCode}
                                    </span>
                                    <span className="block text-base text-left !text-sm">
                                        {filterLabel}
                                        {filterItem.filterKey.startsWith(
                                            'slider_'
                                        )
                                            ? currencyLabel
                                            : ''}
                                    </span>
                                </button>
                            ) : null;
                        });
                    } else {
                        return (
                            <button
                                type="button"
                                onClick={() => {
                                    if (
                                        window.innerWidth <= 1024 &&
                                        mobileScrollRef
                                    ) {
                                        mobileScrollRef.current?.scrollIntoView();
                                    } else {
                                        scrollRef?.current?.scrollIntoView();
                                    }

                                    !Array.isArray(filterItem.value)
                                        ? removeParamFromUrl(
                                              router,
                                              filterItem.filterKey,
                                              filterItem.value
                                          )
                                        : null;
                                }}
                                className={
                                    'block relative bg-gray-100 rounded px-2 py-1 pr-6 mr-1.5 mb-1.5 hover:bg-gray-300 disabled:text-gray-300 disabled:hover:bg-gray-200'
                                }
                                key={filterItem.value}
                                disabled={loading}
                            >
                                <XIcon
                                    className="h-3 w-3 absolute right-1 top-1.5"
                                    aria-hidden="true"
                                />
                                <span className="block !text-xs text-left text-gray-400">
                                    {getFilterCodeLabel(filterItem.filterKey)}
                                </span>
                                <span className="block text-base !text-sm text-left">
                                    {getFilterValue(
                                        filterItem.filterKey,
                                        filterItem.value
                                    )}
                                    {filterItem.filterKey.startsWith('slider_')
                                        ? currencyLabel
                                        : ''}
                                </span>
                            </button>
                        );
                    }
                    return '';
                }
            )}
        </div>
    );
};

export default FiltersCurrents;
