import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
    addParamToUrl,
    removeParamFromUrl,
    checkParamExists
} from '../../util/urlManipulatingFunctions';
import RangeSlider from '../RangeSlider';
import { Form } from 'informed';
import { yesAndNoList } from './filtersTypes';
import { PlusIcon, MinusSmIcon } from '@heroicons/react/outline';
import { RenderColorOrIcon } from './filtersRelatedFunctions';
import Checkbox from '../Checkbox';

type optionsType =
    | {
          value: any;
          label: string;
          count: number;
          frequency?: number;
          image?: string;
          description?: string;
          color?: string;
      }[];
interface FilterOptionsProps {
    options: optionsType;
    attributeCode: string;
    loading: boolean;
    display_mode_label?: string;
    searchFilters?: boolean;
    rangeSliderButtonMode?: boolean;
    isColorType?: boolean;
    scrollRef?: React.RefObject<HTMLElement>;
    mobileScrollRef?: React.RefObject<HTMLElement>;
}

const FilterOptions = ({
    options,
    attributeCode,
    loading,
    display_mode_label,
    searchFilters,
    rangeSliderButtonMode,
    isColorType,
    scrollRef,
    mobileScrollRef
}: FilterOptionsProps) => {
    const router = useRouter();
    const [showOptionsCount, setShowOptionsCount] = useState(3);
    const [maxValBuffer, setMaxValBuffer] = useState(0);
    let handledOptions: optionsType;
    const optionsToShow = 3; //How many options I have to show before open the whole filter
    const filterTypeIsSlider = display_mode_label == 'Slider';
    let minIsZero = true;

    const showEuroSign = ['price'];

    useEffect(() => {
        Object.keys(router.query).length == 1 && setShowOptionsCount(3);
    }, [router]);

    const ShowMoreBtnLess = () => (
        <button
            type="button"
            className="flex justify-start items-center text-sm"
            onClick={() =>
                setShowOptionsCount((prevCount) => {
                    return showOptionsCount >= handledOptions.length
                        ? 3
                        : prevCount + 10;
                })
            }
        >
            {showOptionsCount >= handledOptions.length ? (
                <MinusSmIcon className="h-8 w-8 p-1 mr-2 border border-solid rounded-full" />
            ) : (
                <PlusIcon className="h-8 w-8 p-1 mr-2 border border-solid rounded-full" />
            )}
            {showOptionsCount >= handledOptions.length
                ? 'Weniger anzeigen'
                : 'Mehr anzeigen'}
        </button>
    );

    if (filterTypeIsSlider) {
        let min = -1;
        let max = 0;
        let count = 0;

        let preparedOptions: object[] = [];

        if (searchFilters) {
            options.map((item: { value: any }) => {
                item?.value?.min && item?.value?.max
                    ? preparedOptions.push({
                          label:
                              Math.ceil(item.value.min) +
                              '-' +
                              Math.ceil(item.value.max),
                          value:
                              Math.ceil(item.value.min) +
                              '_' +
                              Math.ceil(item.value.max)
                      })
                    : null;
            });

            if (
                options.filter(
                    (f: {
                        value: {
                            min?: string;
                            max?: string;
                        };
                    }) => !f.value.min && !f.value.max
                ).length > 0
            ) {
                preparedOptions.push({
                    label:
                        Math.ceil(options[0].value) +
                        '-' +
                        Math.ceil(options[options.length - 1].value),
                    value:
                        Math.ceil(options[0].value) +
                        '_' +
                        Math.ceil(options[options.length - 1].value)
                });
                minIsZero = false;
            }
        } else {
            preparedOptions = options;
        }

        preparedOptions.map((item: any) => {
            const splitedStr = item.value.split('_');
            if (max < Number(splitedStr[1])) {
                max = Number(splitedStr[1]);
            }
            if (min > Number(splitedStr[0]) || min == -1) {
                min = Number(splitedStr[0]);
            }
        });
        handledOptions = [
            {
                label: min + '-' + max,
                value: min + '_' + max,
                count: count
            }
        ];
    } else {
        if (yesAndNoList.includes(attributeCode)) {
            handledOptions = [];
            options.map((option) => {
                searchFilters
                    ? option.value == 'Ja'
                        ? handledOptions.push({
                              label: '',
                              value: 'Ja',
                              count: option.count
                          })
                        : handledOptions.push({
                              label: '',
                              value: 'Nein',
                              count: option.count
                          })
                    : option.value == '1'
                    ? handledOptions.push({
                          label: 'Ja',
                          value: '1',
                          count: option.count
                      })
                    : handledOptions.push({
                          label: 'Nein',
                          value: '0',
                          count: option.count
                      });
            });
        } else {
            handledOptions = options;
        }
    }
    return (
        <div className="space-y-4">
            {Array.isArray(handledOptions) &&
                handledOptions?.map((option, optionIdx) => {
                    const isActive = checkParamExists(
                        router,
                        attributeCode,
                        option.value
                    );

                    return filterTypeIsSlider ? (
                        <Form className="px-2" key={option.value}>
                            <RangeSlider
                                min={
                                    minIsZero
                                        ? 0
                                        : Number(
                                              String(option.value).split('_')[0]
                                          )
                                }
                                max={Number(String(option.value).split('_')[1])}
                                defaultValue={router.query[attributeCode]}
                                attributeCode={attributeCode}
                                valueType={
                                    showEuroSign.includes(attributeCode)
                                        ? '€'
                                        : ''
                                }
                                key={option.value}
                                disabled={loading}
                                setMaxValBuffer={setMaxValBuffer}
                                maxValBuffer={maxValBuffer}
                                rangeSliderButtonMode={rangeSliderButtonMode}
                                scrollRef={scrollRef}
                                mobileScrollRef={mobileScrollRef}
                            />
                        </Form>
                    ) : optionIdx < showOptionsCount ? (
                        <label
                            key={option.value}
                            className="flex flex-row-reverse items-center mt-0 filterOptionHover"
                        >
                            <Checkbox
                                field="filter_option"
                                id={`filter-mobile-${attributeCode}-${optionIdx}`}
                                name={`${attributeCode}[]`}
                                //TODO: check why fieldState prop below throws a typescript warning
                                fieldState={{ value: isActive }}
                                onChange={() => {
                                    if (
                                        window.innerWidth <= 1024 &&
                                        mobileScrollRef
                                    ) {
                                        mobileScrollRef.current?.scrollIntoView();
                                    } else {
                                        scrollRef?.current?.scrollIntoView();
                                    }

                                    !isActive
                                        ? addParamToUrl(
                                              router,
                                              attributeCode,
                                              option.value,
                                              filterTypeIsSlider,
                                              yesAndNoList.includes(
                                                  attributeCode
                                              )
                                          )
                                        : removeParamFromUrl(
                                              router,
                                              attributeCode,
                                              option.value
                                          );
                                }}
                                disabled={loading}
                            />
                            <div className="min-w-0 mr-1 flex justify-start items-center text-base w-full font-normal cursor-pointer">
                                <span
                                    className={
                                        option.color || isColorType
                                            ? 'pl-11 relative'
                                            : ''
                                    }
                                >
                                    {option.color ? (
                                        <RenderColorOrIcon
                                            option={option.color}
                                        />
                                    ) : isColorType ? (
                                        <RenderColorOrIcon
                                            option={option.image}
                                            isImage={true}
                                            colorName={
                                                searchFilters
                                                    ? option.value
                                                    : option.label
                                            }
                                        />
                                    ) : null}
                                    {option ? (
                                        <span>
                                            <span className="overflowWrapAnywhere">
                                                {searchFilters
                                                    ? option.value
                                                    : option.label}
                                            </span>
                                            {option.count && !searchFilters ? (
                                                <span className="text-skin-muted ml-1">
                                                    ({option.count})
                                                </span>
                                            ) : option.frequency ? (
                                                <span className="text-skin-muted ml-1">
                                                    ({option.frequency})
                                                </span>
                                            ) : null}
                                        </span>
                                    ) : null}
                                    {/* {option.description ? (
                                        <span className="block w-full text-xs text-skin-muted mt-1">
                                            {option.description}
                                        </span>
                                    ) : null} */}
                                </span>
                            </div>
                        </label>
                    ) : null;
                })}
            {Array.isArray(handledOptions) &&
            handledOptions.length > optionsToShow ? (
                <ShowMoreBtnLess />
            ) : null}
        </div>
    );
};

export default FilterOptions;
