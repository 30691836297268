import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { addParamToUrl } from '../../util/urlManipulatingFunctions';
import classes from './rangeSlider.module.scss';
import { useDebouncedCallback } from 'use-debounce';
import { ArrowRightIcon } from '@heroicons/react/outline';
interface RangeSliderProps {
    min: number;
    max: number;
    valueType: string;
    defaultValue?: string | string[];
    disabled: boolean;
    attributeCode: string;
    setMaxValBuffer: Function;
    maxValBuffer: number;
    rangeSliderButtonMode?: boolean;
    scrollRef?: React.RefObject<HTMLElement>;
    mobileScrollRef?: React.RefObject<HTMLElement>;
}

const RangeSlider = (props: RangeSliderProps) => {
    const {
        min,
        max,
        valueType,
        defaultValue,
        disabled,
        attributeCode,
        setMaxValBuffer,
        maxValBuffer,
        rangeSliderButtonMode,
        scrollRef,
        mobileScrollRef
    } = props;
    const router = useRouter();
    const selectedSliderVal = router.query['slider_' + attributeCode];
    const selectedSliderValMin = Number(
        String(selectedSliderVal).split('_')[0]
    );
    const selectedSliderValMax = Number(
        String(selectedSliderVal).split('_')[1]
    );
    const [selectedValue, setSelectedValue] = useState(
        selectedSliderVal
            ? [selectedSliderValMin, selectedSliderValMax]
            : [min, maxValBuffer]
    );

    const handleMinField = (value: [number, number]) => {
        addParamToUrl(
            router,
            attributeCode,
            value[0] + '_' + value[1],
            true,
            true
        );
    };
    const handleMaxField = (value: [number, number]) => {
        addParamToUrl(
            router,
            attributeCode,
            value[0] + '_' + value[1],
            true,
            true
        );
    };

    useEffect(() => {
        Number.isNaN(max)
            ? null
            : max < maxValBuffer
            ? !selectedSliderVal
                ? setMaxValBuffer(max)
                : null
            : setMaxValBuffer(max);
    }, []);

    useEffect(() => {
        if (!selectedSliderVal) {
            maxValBuffer
                ? setSelectedValue([min, maxValBuffer])
                : setSelectedValue([min, max]);
        } else {
            setSelectedValue([selectedSliderValMin, selectedSliderValMax]);
        }
    }, [selectedSliderVal, maxValBuffer]);

    const marks = {};

    const selectedDefault = defaultValue
        ? [
              Number(String(defaultValue).split('_')[0]),
              Number(String(defaultValue).split('_')[1])
          ]
        : null;

    const debouncedMin = useDebouncedCallback(
        // function
        (value) => {
            if (value) {
                if (value < min || value > selectedValue[1]) {
                    handleMinField([min, selectedValue[1]]);
                    setSelectedValue([min, selectedValue[1]]);
                } else {
                    handleMinField([value, selectedValue[1]]);
                    setSelectedValue([value, selectedValue[1]]);
                }
            }
        },
        // delay in ms
        1000
        // { trailing: false }
    );
    const debouncedMax = useDebouncedCallback(
        // function
        (value) => {
            if (value) {
                if (value > maxValBuffer || value < selectedValue[0]) {
                    handleMaxField([selectedValue[0], max]);
                    setSelectedValue([selectedValue[0], max]);
                } else {
                    handleMaxField([selectedValue[0], value]);
                    setSelectedValue([selectedValue[0], value]);
                }
            }
        },
        // delay in ms
        1000
        // { trailing: false }
    );

    const handleSubmitFields = (value: number[]) => {
        if (value) {
            if (value[0] < min || value[0] > value[1]) {
                handleMinField([min, selectedValue[1]]);
                setSelectedValue([min, selectedValue[1]]);
            } else {
                handleMinField([value[0], selectedValue[1]]);
                setSelectedValue([value[0], selectedValue[1]]);
            }
        }
    };

    return maxValBuffer != 0 ? (
        <div className="px-2">
            <Slider
                range
                disabled={disabled}
                step={1}
                min={min}
                max={maxValBuffer}
                defaultValue={selectedDefault ? selectedDefault : selectedValue}
                pushable={10}
                allowCross={false}
                marks={marks}
                draggableTrack={true}
                onAfterChange={(value: any) => {
                    console.log('mobileScrollRef', mobileScrollRef);
                    if (window.innerWidth <= 1024 && mobileScrollRef) {
                        mobileScrollRef.current?.scrollIntoView();
                    } else {
                        scrollRef?.current?.scrollIntoView();
                    }

                    setSelectedValue(value);
                    addParamToUrl(
                        router,
                        attributeCode,
                        value[0] + '_' + value[1],
                        true,
                        true
                    );
                }}
                ariaValueTextFormatterForHandle={(value) =>
                    value + ' ' + valueType
                }
                trackStyle={[
                    { backgroundColor: !disabled ? '#437681' : '#d3d3d3' }
                ]}
                handleStyle={[
                    {
                        backgroundColor: !disabled ? '#437681' : '#d3d3d3',
                        borderColor: !disabled ? '#437681' : '#d3d3d3',
                        boxShadow: 'none',
                        opacity: 1
                    },
                    {
                        backgroundColor: !disabled ? '#437681' : '#d3d3d3',
                        borderColor: !disabled ? '#437681' : '#d3d3d3',
                        boxShadow: 'none',
                        opacity: 1
                    }
                ]}
                handleRender={(val) => {
                    const handleProps: any = val['props'];
                    const handleVal: any = handleProps['aria-valuetext'];
                    return (
                        <div {...val.props}>
                            <div className="rcSliderVal hidden absolute bottom-5 left-1/2 -translate-x-1/2 w-max p-1 text-xs text-white bg-gray-600 rounded-md before:content-[''] before:absolute before:top-full before:left-1/2 before:-translate-y-1/2 before:-translate-x-1/2 before:w-[6px] before:h-[6px] before:rotate-45 before:bg-gray-600">
                                {handleVal}
                            </div>
                        </div>
                    );
                }}
                className={classes.removeDisabledStyle}
                // railStyle={{ backgroundColor: 'black' }}
            />
            <div className="flex justify-center items-center mt-2">
                <input
                    type="number"
                    name="min-val"
                    className="w-16 h-8 text-center text-md font-bold"
                    min={min}
                    max={maxValBuffer}
                    step={1}
                    value={selectedValue[0].toString()}
                    onChange={(e) => {
                        const value = Number(e.target.value);
                        setSelectedValue([Number(value), selectedValue[1]]);
                        rangeSliderButtonMode ? null : debouncedMin(value);
                    }}
                />
                <span className="mx-2 text-sm">bis</span>
                <input
                    type="number"
                    name="max-val"
                    className="w-16 h-8 text-center text-md font-bold"
                    onChange={(e) => {
                        const value = Number(e.target.value);
                        setSelectedValue([selectedValue[0], value]);
                        rangeSliderButtonMode ? null : debouncedMax(value);
                    }}
                    min={min}
                    max={maxValBuffer}
                    step={1}
                    value={selectedValue[1].toString()}
                />
                {rangeSliderButtonMode ? (
                    <button
                        type="button"
                        onClick={() => handleSubmitFields(selectedValue)}
                    >
                        <span className="flex items-center justify-center h-8 px-2 bg-skin-secondary text-white rounded ml-2">
                            <ArrowRightIcon className="h-6 w-6" />
                        </span>
                    </button>
                ) : null}
            </div>
        </div>
    ) : null;
};

export default RangeSlider;
