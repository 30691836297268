import Image from 'next/image';
export const RenderColorOrIcon = ({
    option,
    productPageMode,
    isImage,
    isActive,
    colorName
}: any) => {
    const generateImageUrl = () => {
        const imageName = colorName?.toLowerCase() + '.png';

        return (
            '/pub/media/filters/' +
            imageName
                .replace(/ß/g, 's')
                .replace(/ü/g, 'u')
                .replace(/ö/g, 'o')
                .replace(/ä/g, 'a')
        );
    };
    if (!isImage) {
        return (
            <span
                className={
                    productPageMode
                        ? isActive
                            ? 'w-12 h-12 block rounded-full hover:border-skin-blue border-skin-blue border-4 border-solid hover:border-4 hover:border-solid'
                            : 'w-12 h-12 block rounded-full hover:border-skin-blue border-skin-primary border border-solid hover:border-2 hover:border-solid'
                        : 'absolute left-0 -top-1 block w-8 h-8 rounded-full'
                }
                style={{
                    backgroundColor: option,
                    border: `${option == '#FFFFFF' ? 'solid 1px #707070' : ''}`
                }}
            />
        );
    } else {
        return productPageMode ? (
            <div
                className={`flex justify-center items-center p-2 ${
                    isActive
                        ? 'hover:border-skin-blue border-skin-blue border-2 border-solid hover:border-2 hover:border-solid mr-2'
                        : 'border-skin-primary border border-solid mr-2'
                }
                `}
            >
                <Image
                    src={option}
                    alt=""
                    width={50}
                    height={50}
                    // layout="responsive"
                    objectFit="contain"
                />
            </div>
        ) : (
            <span
                className={
                    productPageMode
                        ? isActive
                            ? 'w-12 h-12 block rounded-full hover:border-skin-blue border-skin-blue border-4 border-solid hover:border-4 hover:border-solid'
                            : 'w-12 h-12 block rounded-full hover:border-skin-blue border-skin-primary border border-solid hover:border-2 hover:border-solid'
                        : 'absolute left-0 -top-1 block w-8 h-8 rounded-full'
                }
                style={{
                    backgroundImage: 'url(' + generateImageUrl() + ')',
                    backgroundSize: 'cover'
                }}
            />
        );
    }
};
