const listFindlogicFilters = [
    'werbung',
    'smarthome',
    'bulb_changeable',
    'online_only',
    'lighting_included',
    'furniture_kind',
    'lamp_kind',
    'brand',
    'lightcolor',
    'lightcolor_filter',
    'filtercolor',
    'socket',
    'backrest_real',
    'bed_drawer',
    'carpet_shape',
    'closure_type',
    'dining_chair_type',
    'dishwasher_safety',
    'doors_type',
    'extendable',
    'hardness_degree_matress',
    'height_adjustable',
    'induction_safety',
    'matress_inclusive',
    'matress_type',
    'mirror_door',
    'oven_safety',
    'material_bezug',
    'slatted_frame_adjustable',
    'stainless',
    'table_frame_shape',
    'tabletop_shape',
    'sale_stores',
    'washing_safety',
    'oven_safety',
    'dryer_safety',
    'oven_energieclass',
    'freezer_energieclass',
    'cooker_hood_energieclass',
    'dishwasher_energieclass',
    'dimensions_pillow',
    'color',
    'filter_stellbreite',
    'filter_hoehe',
    'filter_breite',
    'filter_lange',
    'filter_tiefe',
    'color_lamp',
    'light_flux',
    'breadth_lying_surface',
    'carrying_capacity',
    'price_tag',
    'opticard_special_price_display',
    'cat_url',
    'cat',
    'hob',
    'fast_delivery',
    'sale',
    'kitchen_kind',
    'vendor',
    'price',
    'material'
];

export default listFindlogicFilters;
