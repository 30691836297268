import { useRouter } from 'next/router';
import { clearAllParams } from '../../util/urlManipulatingFunctions';
import Button from '../Button';

const NoProducts = ({ isSearchFilter }: { isSearchFilter: boolean }) => {
    const router = useRouter();
    return (
        <div className="text-center">
            <h2>Hoppla, leider gibt es für Ihre Suche kein Produkt</h2>
            <Button
                type="button"
                className="mx-auto"
                onClick={() => {
                    clearAllParams(router, isSearchFilter);
                }}
            >
                Alle Filter löschen
            </Button>
        </div>
    );
};

export default NoProducts;
